import React, { Component } from "react";
import cx from 'classnames';
// import styles from "../assets/styles/brokerProfileInfo.module.css";
import styles from "../assets/styles/brokerProfileTabs.module.css";
import BrokerProfileReviews from "./brokerProfileReviews";
import BrokerProfileDeals from "./brokerProfileDeals";
// import BrokerProfileQA from "./brokerProfileQA";

class BrokerProfileTabs extends Component {
  constructor() {
    super();
    this.state = {
      activeTabId: 0
    }
  }

  changeTab(id) {
    this.setState({
      activeTabId: id
    })
  }
  onClickShareReviewClose() {

    if (document.getElementById('share-popup-msg') && !!document.getElementById('share-popup-msg').value) {
      let url = document.getElementById("postReviewOnSocial").getAttribute("href");
      let oMessage = document.getElementById('share-popup-msg').value;
      url = url + '&text=' + oMessage;
      document.getElementById("postReviewOnSocial").setAttribute("href", url);
    }

    document.getElementById("reviewPopup").style.WebkitAlignContent = "none";
    document.getElementById("reviewPopup").style.display = "none"
    document.getElementById("reviewPopup").classList.remove("modal-custom-bootstrap");
    document.body.classList.remove('modal-open-custom-bootstrap');

  }

  render() {
    const data = this.props.data; 
    
    //     console.log(data.broker_details.brokerGoogleReviews)
    const ActiveTab = ((id) => {
      switch (id) {
        case 0: return (<BrokerProfileReviews data={data.broker_details.leadReviews} brokername={data.fullName} brokertype={data.premium} brokerprofilepic={data.profile_pic} SlugBrokerDetails={data.profile_view} />);
        // case 1: return (<BrokerProfileDeals {...this.props} deals={data.broker_details.deals_detail} allDeals={this.props.deals} />);
        // case 2: return (<BrokerProfileQA data={data.broker_details.brokerQA.questions} qCount={data.broker_details.brokerQA.count} />);
        default: return null;
      }
    })(this.state.activeTabId);
    const style = {
      //   padding: 12,
      // marginRight: 30,
      // cursor: 'pointer'
    };
    const activeStyle = {
      // padding: 12,
      // marginRight: 30,
      // cursor: 'pointer',
      borderBottom: '6px solid #ff8a23',
      fontWeight: 'bold'
    };


    return (
      <div className={styles.profileTab}>
        <div className={cx('card', styles.profileListingCard, styles.card)}>
          <div className={cx('card-header', styles.cardHeader, styles.profileListingTab)}>
            <a id="brokerwallReviewId" className={cx(this.state.activeTabId === 0 ? style.activeTab : '', styles.tabLink)} style={this.state.activeTabId === 0 ? activeStyle : style}
              onClick={this.changeTab.bind(this, 0)}>
              Reviews <span className={cx('funnlReviewcounts', styles.counts)}> ({data.review_count}) </span></a>
            {/* <a className={cx(styles.tabLink)} style={this.state.activeTabId===1 ? activeStyle : style} */}

            {/* <a id="brokerwallDealId" className={cx(this.state.activeTabId===1 ? style.activeTab : '', styles.tabLink)} style={this.state.activeTabId===1 ? activeStyle : style} 
                  onClick={this.changeTab.bind(this, 1)}>
                  Deals <span className={(styles.counts)}> ({data.deals_count}) </span></a> */}


            {/* <a className={cx(styles.tabLink)} style={this.state.activeTabId===2 ? activeStyle : style} */}
            {/* <a id="brokerwallQuesAnsId" className={cx(this.state.activeTabId === 2 ? style.activeTab : '', styles.tabLink, styles.qatab)} style={this.state.activeTabId === 2 ? activeStyle : style}
              onClick={this.changeTab.bind(this, 2)}>
              Questions <br /> Answered <span className={(styles.counts)}> ({data.broker_details.brokerQA.count}) </span></a> */}
          </div>
          <div className={cx('card-body', styles.cardBody)}>
            {ActiveTab}

          </div>
        </div>
        <div id="reviewPopup" className={styles.reviewModelPopup} style={{ display: 'none' }}>
          <div className={styles.popupinner}>
            <h4 className={styles.popuptitle}>Share Review</h4>
            <a className={styles.popupClose} href="javascript:void(0)" onClick={this.onClickShareReviewClose.bind(this)}>&times;</a>
            <div id="reviewPopupLoading"></div>
            <div className={styles.shareReviewImgMain} id="reviewPopupReview">
              <img className={styles.reviewImg} src="" />
            </div>
            <div className={cx('form-group', styles.entrMsg)}>
              <textarea id="share-popup-msg" className={cx('form-control', styles.formControl)} placeholder="Enter your message"></textarea>
            </div>
            <div className={styles.submtBtns}>
              <div className={cx(styles.btnMain, styles.postBtn)}>
                <a id="postReviewOnSocial" target="_blank" href="javascript:void(0);" onClick={this.onClickShareReviewClose.bind(this)} className={cx('btn', styles.btn)}>Post</a>
              </div>
              <div className={cx(styles.btnMain, styles.canclBtn)}>
                <a onClick={this.onClickShareReviewClose.bind(this)} href="javascript:void(0);" className={cx('btn', styles.btn)}>Cancel</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default BrokerProfileTabs;