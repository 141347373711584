import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import AboutPage from '../components/aboutPage';
// import HomeHero from '../components/v2/homeHero';
import HomeNavlinksMobview from '../components/homeNavlinksMobview';
import Services from '../components/services';
import WhyWeAreBetter from '../components/whyWeAreBetter';
import AboutUs from '../components/aboutUs';
//import HashchingReviews from '../components/hashchingReviews';
import RangeOfLenders from '../components/rangeOfLenders';
import HomeBottomGetStarted from '../components/homeBottomGetStarted';
// import FooterBottomContact from '../components/footerBottomContact';
import CustomHelmet from '../components/customHelmet';
import { timeout } from 'q';
import LazyLoad from "react-lazy-load";
import BrokerHero from '../components/brokerHero';
import AboutHashching from '../components/aboutHashching';
import HashchingInNews from '../components/hashchingInNews';
import HashchingRatings from '../components/hashchingRatings';
import HashchingStats from '../components/hashchingStats';
import HashchingLoans from '../components/hashchingLoans';
import allKenticoCloudItemPageHome from "../../data/kentico/allKenticoCloudItemPageHome";
import allKenticoCloudItemSectionHeader from "../../data/kentico/allKenticoCloudItemSectionHeader";
import allKenticoCloudItemLeadSCount from "../../data/kentico/allKenticoCloudItemLeadSCount";
import Bankers from "../components/bankers";
import BorrowingCalculator from '../components/borrowing-calculator';
import { initAnimations } from "../helpers/commons";
import "../assets/styles/animations.css"

// const AddClassInBody = () => {
//     if (typeof document != 'undefined') {
//       if (document.getElementsByTagName('body') 
//         && typeof document.getElementsByTagName('body') == 'object') {
//         let body = document.getElementsByTagName('body')[0];
//         body.classList.add("home-page-b-cl");
//       }
//     }
// }



const Index = ({ data }) => {
  useEffect(() => {
 //   window.location.replace('/mortgage-broker-finder');
    initAnimations();
  }, []);
  
  const node = allKenticoCloudItemPageHome.edges[0].node,
    // seo_title = node.elements.section___seo_content_508c18c__seo_title.value,
    // seo_description = node.elements.section___seo_content_508c18c__seo_description.value,
    // seo_keywords = node.elements.section___seo_content_508c18c__seo_keywords.value,
    seo_canonicalurl = node.elements.section___seo_content_508c18c__seo_canonicalurl.value;
    // heroSection = node.elements.hero_banner[0].elements,
    // comparisonDisclaimer = node.elements.comparison_rate_disclaimer,
    // allServices = node.elements.services[0].elements,
    // allBrokerDetail = data.allHashchingBroker,
    // why_we_are_better = node.elements.why_we_are_better[0].elements,
    // about_us = node.elements.about_us[0].elements,
    // we_featured_in = node.elements.we_featured_in[0].elements,
    // hashching_reviews = node.elements.hashching_reviews[0].elements,
    // range_of_lenders = node.elements.range_of_lenders[0].elements,
    // get_started_section = node.elements.get_started_section[0].elements,
    // leads_count = allKenticoCloudItemLeadSCount.edges[0].node.elements.label_leads_count.value,
    // homelinksmobview = allKenticoCloudItemSectionHeader;
    // console.log(homelinksmobview);
  return (
    <div className={'homePageMain'}>
      <Layout>
        <CustomHelmet>
          {/* <title>{seo_title}</title> */}
          <title>HFG Marketplace - Home Loan Comparison | Compare Mortgage Rates</title>
          {/* <meta name="description" content={seo_description} /> */}
          <meta name="description" content="HFG Marketplace helps you compare home loan deals online in a marketplace of pre-negotiated variable &amp; fixed home loan rates from your local mortgage brokers." />
          {/* <meta name="keywords" content={seo_keywords} /> */}
          <meta name="keywords" content="HFG Marketplace" />
          <meta property="og:description" content="HFG Marketplace helps you compare home loan deals online in a marketplace of pre-negotiated variable &amp; fixed home loan rates from your local mortgage brokers." />
          {/* <meta property="og:title" content={seo_title} /> */}
          <meta property="og:title" content="HFG Marketplace - Home Loan Comparison | Compare Mortgage Rates" />
          <meta property="og:url" content={seo_canonicalurl} />
          <meta property="og:image" content="https://cdn.hashching.com.au/assets/images/frontend/website_img.jpg" />
          <meta name="twitter:description" content="HFG Marketplace helps you compare home loan deals online in a marketplace of pre-negotiated variable &amp; fixed home loan rates from your local mortgage brokers." />
          {/* <meta name="twitter:title" content={seo_title} /> */}
          <meta name="twitter:title" content="HFG Marketplace - Home Loan Comparison | Compare Mortgage Rates" />
          <link rel="canonical" href={seo_canonicalurl} />
        </CustomHelmet>
        {/* <AboutPage/> */}
        {/* <HomeHero content={heroSection} disclaimer={comparisonDisclaimer} /> */}
        <BrokerHero/>
        <HashchingStats/>
        {/* <Bankers /> */}
        <HashchingRatings/>        
        {/* <BorrowingCalculator /> */}
        {/* <AboutHashching/> */}
        {/* <HashchingLoans/> */}
        {/* <HashchingInNews/> */}
      </Layout>
    </div>

  );
};

export default Index;

export const query = graphql`
{
  allHashchingBroker(
    filter: { overdue_leads: { eq: "0" }, show_in_directory: { eq: 1 } },
    sort: {
      fields: [GoldAndPremium, broker_details___latest_review_posted_on_new, sort_premium, review_count, broker_rating],
      order: [DESC, DESC, DESC, DESC, DESC]
    },
    limit: 3
  ) {
    edges {
      node { 
        fullName
        profile_pic
        review_count
        broker_rating
        premium
        sort_premium
        GoldAndPremium
        deals_count
        broker_details {
          avgResponse
          latest_review
          latest_review_by
          latest_review_posted_on
          latest_review_posted_on_new
          aggregatorBusinessName
        }
      }
    }
  }
}
`;


Index.propTypes = {
  data: PropTypes.object,
};