import React, { Component } from "react";
import ProfileTabStyle from "../assets/styles/brokerProfileTabs.module.css";
import DealCard from "./deals/dealCard";


class BrokerProfileDeals extends Component {
  constructor() {
    super();
    this.state = {
      threshold: 10,
      collapsed: true,
      hash:true,
      deaLSort:'1'
    }
  }

  changeCollapsed() {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  onChangeSort(value)
    {
       this.setState({
          deaLSort: value
        });

    }
  calculateMontlyRepayments(intRate,termRange,mortgageAmount)
    {
      var getDefaultInterestRate = ( intRate / 100 );
      var interestRatePerFrequency = getDefaultInterestRate / 12;
      var A = mortgageAmount;
      var N = 12 * termRange;
      var newRepaymentsAmount = (interestRatePerFrequency * A) / (1 - Math.pow((1 + interestRatePerFrequency), -N));

      if (newRepaymentsAmount== 'Infinity' || newRepaymentsAmount < 1 || isNaN(newRepaymentsAmount)) {
        return 0;
      }
      return Math.round(newRepaymentsAmount).toString();
    }
 sortFeature(param,data)
    {   
      switch(param) {
        case '1':
          return data.sort((a, b) => (a.node.interest_rate - b.node.interest_rate));
        case '2':
          return data.sort((a, b) => (b.node.interest_rate - a.node.interest_rate));
        case '3':
          return data.sort((a, b) => (b.node.comparison_interest_rate - a.node.comparison_interest_rate));
        case '4':
          return data.sort((a, b) => (a.node.comparison_interest_rate - b.node.comparison_interest_rate));
        case '5':
          return data.sort((a, b) => (b.node.monthly_repayments - a.node.monthly_repayments));
        case '6':
          return data.sort((a, b) => (a.node.monthly_repayments - b.node.monthly_repayments));
        case '7':
          return data.sort((a, b) => (b.node.loan_to_value_ratio - a.node.loan_to_value_ratio));
        case '8':
          return data.sort((a, b) => (a.node.loan_to_value_ratio - b.node.loan_to_value_ratio)); 
        case '9':
          return data.sort(function (a, b) {
            let va = a.node.application_fees.match(/[\d.]+/);
            let vb = b.node.application_fees.match(/[\d.]+/);
            if (va !== null) {
              va = Number(va[0]);
            } else {
              va = 0
            }
            if (vb !== null) {
              vb = Number(vb[0]);
            } else {
              vb = 0
            }
            if (va > vb) return -1;
            if (va < vb) return 1;
            return 0;
          });
        case '10':
          return data.sort(function (a, b) {
            let va = a.node.application_fees.match(/[\d.]+/);
            let vb = b.node.application_fees.match(/[\d.]+/);
            if (va !== null) {
              va = Number(va[0]);
            } else {
              va = 0
            }
            if (vb !== null) {
              vb = Number(vb[0]);
            } else {
              vb = 0
            }
            if (va < vb) return -1;
            if (va > vb) return 1;
            return 0;
          });
        case '11':
          return data.sort(function (a, b) {
            let va = a.node.ongoinFees.match(/[\d.]+/);
            let vb = b.node.ongoinFees.match(/[\d.]+/);
            if (va !== null) {
              va = Number(va[0]);
            } else {
              va = 0
            }
            if (vb !== null) {
              vb = Number(vb[0]);
            } else {
              vb = 0
            }
            if (va > vb) return -1;
            if (va < vb) return 1;
            return 0;
          });
        case '12':
          return data.sort(function (a, b) {
            let va = a.node.ongoinFees.match(/[\d.]+/);
            let vb = b.node.ongoinFees.match(/[\d.]+/);
            if (va !== null) {
              va = Number(va[0]);
            } else {
              va = 0
            }
            if (vb !== null) {
              vb = Number(vb[0]);
            } else {
              vb = 0
            }
            if (va < vb) return -1;
            if (va > vb) return 1;
            return 0;
          });
        default:
          return data.sort((a, b) => (a.node.interest_rate - b.node.interest_rate));;
      }
    }
  render() {
    const deals = this.props.deals || [];
    let allDealsLenght = deals.length;
    let allDeals = this.props.allDeals;
    allDeals.map((deal) => deal.node.monthly_repayments = this.calculateMontlyRepayments(deal.node.interest_rate,25,500000))
    let brokerDeals = allDeals.filter(({node:{deal_id}}) => deals.filter(d=>d.id===deal_id).length>0);
    /** Sort deals **/
    brokerDeals = this.sortFeature(this.state.deaLSort,brokerDeals)
    /****************/
    if (this.state.collapsed) {
      brokerDeals = brokerDeals.slice(0, this.state.threshold);
    }
    return (
      <div>
        <DealCard  {...this.props} allDeals={brokerDeals} fromBrokerProfile={true} parentClass={'BrokerDealListing'} hashTag={this.state.hash} compareDeals={this.props.compareDeals} DealsSelectedForCompare={this.props.DealsSelectedForCompare} onChangeSort={this.onChangeSort.bind(this)}/>
        { (allDealsLenght > this.state.threshold) ? (<div className={ProfileTabStyle.viewallMain}>
                        <a className={ProfileTabStyle.viewallLink} href="javascript://" 
                          onClick={this.changeCollapsed.bind(this)}>
                          { this.state.collapsed ? 'See all deals' : 'See less deals' }
                        </a>
                      </div>) : ''}
          

      </div>       
    )
  }
}

export default BrokerProfileDeals;