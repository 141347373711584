import React, { Component, Fragment } from "react";
import cx from 'classnames';
// import Styles from '../assets/styles/brokerFinder.module.css';
import Styles from '../assets/styles/autoSearchBroker.module.css';
import Axios from "axios";
import Cookies from 'universal-cookie';
import { navigate } from "gatsby"
import { log, host } from '../helpers/commons'


class Autocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      suggestions: [],
      userInput: "",
      suggestionClass: 'closeSuggestionClass',
      message: 'Please select postcode from list',
      requestpostcode: '',
      flag: false,
      errorMsgClass: 'errorMsgHide',
      postcodeId: '',
      placeholder: this.props.searchkenticoPlaceholder.search_placeholder.value
    };
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);

  }

  onChange(event) {
    let userElement = event.currentTarget;
    let userInput = userElement.value;
    let postcode = [];
    let _this = this;
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    this.state.flag = false;    

    if (userInput.length >= 3) {
      if (parseInt(userInput) != 0 || parseInt(userInput) == 'NaN') {
        const url = `${host}/gatsby-api/suburblookup?suburblookup=${userInput}`
        if (this.state.requestpostcode) {
          source.cancel();
        }
        //add loader
        userElement.classList.add("loader-right");

        this.state.requestpostcode = Axios.get(url, {
          cancelToken: source.token
        }).catch(function (error) {
          if (Axios.isCancel(error)) {
            console.log('Request canceled');
          } else {
            console.error(error);
          }
        }).then(function (response) {
          if (response) {
            postcode = response.data.postcodes;
            if (postcode.length) {
              _this.setState({
                suggestions: postcode,
                suggestionClass: 'openSuggestionClass',
                message: 'Please select postcode from list',
                requestpostcode: null
              });
            } else {
              _this.setState({
                suggestions: postcode,
                message: 'Please enter a valid postcode',
                requestpostcode: null
              });
            }
            //remove loader   
            userElement.classList.remove("loader-right");
          } else {
            console.error('no response');
            //remove loader   
            userElement.classList.remove("loader-right");
          }
        })
      }
    }
    if (userInput.length > 0) {
      _this.setState({
        userInput: event.currentTarget.value,
        errorMsgClass: 'errorMsgHide'
      });
    } else {
      _this.setState({
        userInput: event.currentTarget.value,
        suggestionClass: 'closeSuggestionClass',
        errorMsgClass: 'errorMsgHide'
      });
    }

  }

  onClick(event, id, postcode) {
    let _this = this;
    _this.setState({
      userInput: event.currentTarget.innerText,
      suggestionClass: 'closeSuggestionClass',
      postcodeId: id,
      longitude: postcode.longitude,
      latitude: postcode.latitude,
      flag: true
    });
  }

    // Event handler to clear the userInput
    handleClearInput = () => {
      this.setState({ userInput: '', suggestionClass: 'closeSuggestionClass' }); 
    };

  onClickSearch(event) {
    var userInput = this.state.userInput;
    var flag = this.state.flag;
    let _this = this;
    let url = '';
    if (userInput && flag) {
      var postcodes = userInput.split(',');
      const cookies = new Cookies();
      cookies.set('postcode_id', this.state.postcodeId, { path: '/' });
      cookies.set('postcode', postcodes[0], { path: '/' });
      cookies.set('locality', postcodes[1], { path: '/' });
      cookies.set('statecode', postcodes[2], { path: '/' });
      cookies.set('longitude', this.state.longitude, { path: '/' });
      cookies.set('latitude', this.state.latitude, { path: '/' });

      url = `/mortgage-broker-finder`
      return navigate(url)
    } else {
      _this.setState({
        suggestions: [],
        message: 'Please enter a valid postcode.',
        suggestionClass: 'closeSuggestionClass',
        errorMsgClass: 'errorMsgShow'
      });
    }
  }

  onKeyDown() {
    let _this = this;
    _this.setState({
      suggestions: [],
      message: 'Please enter a valid postcode.',
      suggestionClass: 'closeSuggestionClass',
    });
  }


  render() {
    const suggestionClass = this.state.suggestionClass;
    const suggestionMessage = this.state.message;
    const errorMessage = this.state.errorMsgClass;
    const searchKenticoPlaceHolder = this.props.searchkenticoPlaceholder
    let simplified = (this.props.searchkenticoPlaceholder.simplified) ? Styles.simplified : '';

    return (
      <div className={cx(Styles.serchsuburb, simplified)}>
        <div className={cx(Styles.inputGroup, 'input-group')}>
          <input type="text" className={cx(Styles.formControl, 'form-control')} onChange={this.onChange} onKeyDown={this.onKeyDown} value={this.state.userInput} placeholder={this.state.placeholder} onFocus={() => this.setState({ placeholder: '' })} name="" />
          <div id="clearAutoField" className={cx("input-group-append", Styles.inputClearBtn)}
            style={{
              transition: 'opacity 0.3s ease',
              opacity: this.state.userInput != '' ? 1 : 0,
            }}

            onClick={this.handleClearInput}
          >
            <a className="btn d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                <defs>
                  <clipPath id="clip-Custom_Size_1">
                    <rect width="32" height="32" />
                  </clipPath>
                </defs>
                <g id="Custom_Size_1" data-name="Custom Size – 1" clip-path="url(#clip-Custom_Size_1)">
                  <g id="Group_1" data-name="Group 1" transform="translate(6.5 6.5)">
                    <line id="Line_1" data-name="Line 1" x2="16" y2="16" transform="translate(1.5 1.5)" fill="none" stroke="#707070" stroke-linecap="round" stroke-width="2" />
                    <line id="Line_2" data-name="Line 2" y1="16" x2="16" transform="translate(1.5 1.5)" fill="none" stroke="#707070" stroke-linecap="round" stroke-width="2" />
                  </g>
                </g>
              </svg>
            </a>
          </div>
          <div className={cx(Styles.inputGroupAppend, 'input-group-append')}>
            <button className={cx(Styles.serchbtn, 'btn')} type="submit" onClick={this.onClickSearch}>{searchKenticoPlaceHolder.search_buttontext.value}</button>
          </div>

        </div>

        <div className={cx(Styles.suburbContainer, Styles.findersuburbcontainer, Styles[suggestionClass])}>
          <div className={Styles.autoSuggestCodes}>
            <div className={Styles.arrowup}></div>
            <p className={Styles.selectPostcodeHeding} style={{ display: 'none' }}>
              {suggestionMessage}
            </p>
            <ul>
              {
                this.state.suggestions.map((postcode) =>
                  <li key={postcode.id} onClick={(event) => { this.onClick(event, postcode.id, postcode) }}>
                    <a href="javascript://"  >{`${postcode.postcode}, ${postcode.locality}, ${postcode.statecode}`} </a>
                  </li>
                )
              }
            </ul>
          </div>
        </div>
        <p className={cx(Styles.errorMsg, Styles[errorMessage])}>{suggestionMessage}</p>
      </div>

    );
  }
}

export default Autocomplete;
