import React, { Component } from "react";
import cx from 'classnames';
import logo from "../assets/images/logo.png";
// import headerNeo from "../assets/images/header-neo-new.png";
import Styles from '../assets/styles/newHeader.module.css';
import { log, host } from '../helpers/commons'
// import calculatorImage from "../assets/images/loanCalculatorBg.png";
// import userIcon from "../assets/images/user-icon.svg";
import Axios from "axios";
import Cookies from 'universal-cookie';
import { Link, navigate } from 'gatsby';
// import LoginDropdowns from './loginDropdowns';
// import CustomScroll from 'react-custom-scroll';

// new header start
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openClass: '',
            loanTypes: [],
            menu: null,
            isLoaded: false
        };
        this.handleMenuToggle = this.handleMenuToggle.bind(this);
    }

    componentWillMount() {
        // if (typeof window !== "undefined") {
        //     window.removeEventListener("scroll", this.handleScroll);
        // }
    }

    componentDidMount() {
        let oState = this;
        // if(oState.state.loanTypes.length === 0){
        //     const url = host + '/gatsby-api/fetch-loan-types/';        
        //     Axios.get(url, { }).catch(function (error) {
        //         //console.error(error);
        //     }).then((response) => {
        //         //console.log(response.data);
        //         oState.setState({ loanTypes: response.data.loanTypes });
        //         //console.log(oState.loanTypes);
        //     });
        // }

        if (typeof window !== "undefined") {
            window.removeEventListener("scroll", this.handleScroll);
        }

        oState.setState({ loanTypes: [] });

        //     const url = host + '/gatsby-api/get-login-status';

        //     Axios.get(url, {

        //     }).catch(function (error) {
        //         console.error(error);
        //     }).then((response) => {
        //         this.setState({
        //             isLoaded: true
        //         })
        //         if (typeof response.data.type != 'undefined') {
        //             let data = response.data;
        //             let links = []
        //             Object.getOwnPropertyNames(data.links).forEach(key => {
        //                 let obj = {};
        //                 obj[key] = data.links[key];
        //                 links.push(obj);
        //             })
        //             data.links = links;
        //             if (this.props.onUpdateMenuData) {
        //                 this.props.onUpdateMenuData(response.data, true);
        //             }
        //             this.setState({
        //                 menu: response.data,
        //                 isLoaded: true
        //             })
        //         } else {
        //             if (this.props.onUpdateMenuData) {
        //                 this.props.onUpdateMenuData(response.data, false);
        //             }
        //         }
        //     })
    }

    // handleScroll() {
    //     const header = document.querySelector(".newheaderMain");

    //     if (window.scrollY > 100) {
    //         header?.classList.add("stickyHeader");
    //     } else {
    //         header?.classList.remove("stickyHeader");
    //     }
    // }

    handleScroll() {
        const header = document.getElementById("mainHeaderDiv");
        if (header) {
            if (window.scrollY > 100) {
                header.classList.add(Styles.stickyHeader);
            } else {
                header.classList.remove(Styles.stickyHeader);
            }
        }
    }

    handleMenuToggle() {
        this.setState((prevState) => {
            let openClass = '';
            let menuBarGroupClass = "." + Styles.menuBarGroup;
            if (prevState.openClass == '') {
                openClass = Styles.active
                document.querySelector(menuBarGroupClass).classList.add(Styles.x);
            }else{
                document.querySelector(menuBarGroupClass).classList.remove(Styles.x);
            }

            return {
                openClass: openClass                
            }
        })
    }

    losestByClass(el, clazz) {
        // Traverse the DOM up with a while loop
        while (el.className != clazz) {
            // Increment the loop to the parent node
            el = el.parentNode;
            if (!el) {
                return null;
            }
        }
        // At this point, the while loop has stopped and `el` represents the element that has
        // the class you specified in the second parameter of the function `clazz`

        // Then return the matched element
        return el;
    }

    // handleMenuMoreToggle(event) {
    //     let parent = this.losestByClass(event.target, 'tree-parent');

    //     if (parent) {
    //         let childrenUL = parent.querySelector('.dropdown-menu');
    //         //let icon = parent.querySelector('.ps-plus-icon');

    //         if (childrenUL) {
    //             let hasClass = false;

    //             for (let i = 0; i < childrenUL.classList.length; i++) {
    //                 let cl = childrenUL.classList[i];
    //                 if (cl == Styles["show"]) {
    //                     hasClass = true;
    //                     break;
    //                 }
    //             }

    //             if (!hasClass) {
    //                 this.closeMenuItems();
    //                 childrenUL.classList.add(Styles["show"]);
    //                 //icon.classList.add(Styles["upArrow"]); 
    //             } else {
    //                 childrenUL.classList.remove(Styles["show"]);
    //                 //icon.classList.remove(Styles["upArrow"]); 
    //             }
    //         }
    //     }
    // }

    closeMenuItems() {
        let allOpen = document.getElementsByClassName(Styles["show"]);
        let allUpArrows = document.getElementsByClassName(Styles["upArrow"]);

        Array.from(allOpen).forEach(function (element) {
            element.classList.remove(Styles["show"]);
        });

        Array.from(allUpArrows).forEach(function (element) {
            element.classList.remove(Styles["upArrow"]);
        });
    }

    clickHandler(event) {
        event.preventDefault();
        const cookies = new Cookies();
        cookies.remove('broker', { 'path': '/' });
        cookies.remove('deal', { 'path': '/' });
        cookies.remove('funnelData', { path: '/' })
        cookies.remove('SaveEmail', { path: '/' })
        cookies.remove('SaveName', { path: '/' })
        if (typeof (Storage) !== "undefined") {
            sessionStorage.removeItem("landingData");
        }
        navigate('/loan-enquiry')
    }

    render() {
        const cookies = new Cookies();
        let isLogin = false;
        let role = ''
        let loginClass = 'headrLogin', profile_pic = '', decodedData = {}, loginLinkk = '/your-profile';

        if (this.state.isLoaded && this.state.menu && this.state.menu.type) {
            isLogin = true;
            role = this.state.menu.type;
        }
        //console.log(role, 'current menu =============>')
        if (cookies.get('__gtby')) {
            loginClass = 'profileLogin'
            var base64 = require('base-64');
            decodedData = base64.decode(cookies.get('__gtby'));
            if (decodedData) {
                decodedData = JSON.parse(decodedData);
            }

            if (decodedData.type == 'broker') {

                loginLinkk = '/broker#dashboard'
            }
            else if (decodedData.type == 'consumer') {
                loginLinkk = '/consumer#dashboard'
            }
            profile_pic = "https://s3-ap-southeast-2.amazonaws.com/hashching/uploads/cropper_images/" + decodedData.profile;
        }

        return (
            <div>
                <div className={cx(Styles.mobNavOverlay, this.state.openClass)} onClick={() => { this.handleMenuToggle(); }}></div>
                <header id="mainHeaderDiv" className={cx(Styles.newheaderMain)}>
                    <div className={"container"}>
                        <div className={cx(Styles.headerPart)}>
                            <div className={'container-fluid'}>
                                <div className={cx('row justify-content-between', Styles.mobileHeader)}>
                                    <div className={cx('col-8', Styles.headrLogo)}>
                                        <a href="/">
                                            <img src={logo} alt="Hashching home loans" />
                                        </a>
                                    </div>
                                    <div className={cx('col-sm-4 col-xs-3 col-3', Styles.dFlex)}>
                                        <div className={cx(Styles.headrLeft, Styles.mobheaderLeft)}>
                                            <svg id="noun_menu_592440" xmlns="http://www.w3.org/2000/svg" width="25.592" height="18.207" viewBox="0 0 25.592 18.207" onClick={() => {
                                                this.handleMenuToggle();
                                            }}>
                                                <g class={Styles.menuBarGroup} id="Group_531" data-name="Group 531">
                                                    <rect class={cx(Styles.line, Styles.line1)} x="0" y="0" width="25.592" height="2" fill="#FFFFFF"></rect>
                                                    <rect class={cx(Styles.line, Styles.line2)} x="0" y="8" width="25.592" height="2" fill="#FFFFFF"></rect>
                                                    <rect class={cx(Styles.line, Styles.line3)} x="0" y="16" width="25.592" height="2" fill="#FFFFFF"></rect>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <nav className={cx('navbar navbar-expand-lg mobViewNavBar', this.state.openClass, Styles.customNavbar, Styles.mobViewNavBar, Styles.navbar, Styles.navbarExpandLg)}>
                            <a className={cx("navbar-brand", Styles.navbarBrand)} href="https://hashfinancialgroup.com/">
                                <img src={logo} title="hfg-logo" alt="" className={""} loading="lazy" />
                            </a> 
                            <div className={cx('collapse navbar-collapse', Styles.navbarCollapse, Styles.MainNavigation)} id="navbarNavDropdown">
                                <ul className={cx('navbar-nav', Styles.navbarNav)}>
                                    <li className={cx('nav-item dropdown', Styles.navItem, Styles.dropdown)}>
                                        <div className="tree-parent">
                                            <a className={cx('nav-link dropdown-toggle', Styles.hasNav, Styles.show, Styles.navLink, Styles.dropdownToggle)}
                                                id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false" 
                                                // onClick={(event) => {
                                                //     this.handleMenuMoreToggle(event);
                                                // }}
                                                >
                                                <span> Our Products </span>
                                                <span className={cx(Styles.subArrow, Styles.psPlusIcon)}><svg className="e-font-icon-svg e-fas-angle-down" viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg></span>
                                            </a>
                                            <div className={cx('dropdown-menu', Styles.dropdownMenu, Styles.loanMenuItems)}>
                                                <a className={cx('dropdown-item', Styles.dropdownItem)} href="https://hashfinancialgroup.com/our-products/commercial-loans/">Commercial Loans</a>
                                                <a className={cx('dropdown-item', Styles.dropdownItem)} href="https://hashfinancialgroup.com/residential-mortgages/">Residential Mortgages</a>
                                                <a className={cx('dropdown-item', Styles.dropdownItem)} href="https://hashfinancialgroup.com/residential-mortgages/">First Home Buyer</a>
                                                <a className={cx('dropdown-item', Styles.dropdownItem)} href="https://hashfinancialgroup.com/asset-backed-credit-fund/">Asset-backed Credit Fund</a>
                                                <a className={cx('dropdown-item', Styles.dropdownItem)} href="https://hashfinancialgroup.com/term-deposits/">Term Desposits</a>
                                            </div>
                                        </div>
                                    </li>
                                    <li className={cx('nav-item', Styles.navItem)}>
                                        <a className={cx('nav-link', Styles.navLink)} href="https://hashfinancialgroup.com.au/deals">Our Deals</a>
                                    </li>
                                    <li className={cx('nav-item dropdown', Styles.navItem, Styles.dropdown)}>
                                        <div className="tree-parent">
                                            <a className={cx('nav-link dropdown-toggle', Styles.hasNav, Styles.show, Styles.navLink, Styles.dropdownToggle)}
                                                id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false" 
                                                // onClick={(event) => {
                                                //     this.handleMenuMoreToggle(event);
                                                // }}
                                                >
                                                <span> Our Platforms </span>
                                                <span className={cx(Styles.subArrow, Styles.psPlusIcon)}><svg className="e-font-icon-svg e-fas-angle-down" viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"><path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg></span>
                                            </a>
                                            <div className={cx('dropdown-menu', Styles.dropdownMenu)}>
                                                {/* <a className={cx('dropdown-item', Styles.dropdownItem)} href="https://hashfinancialgroup.com/hashching/">Conveyancing</a> */}
                                                <a className={cx('dropdown-item', Styles.dropdownItem)} href="https://hashfinancialgroup.com/hfg-marketplace/">HFG Marketplace</a>
                                                <a className={cx('dropdown-item', Styles.dropdownItem)} href="/">Mystro</a>
                                                <a className={cx('dropdown-item', Styles.dropdownItem)} href="https://hashfinancialgroup.com/hashprop/">HashProp</a>                                                
                                            </div>
                                        </div>
                                    </li>
                                    <li className={cx('nav-item', Styles.navItem)}>
                                        <a className={cx('nav-link', Styles.navLink)} href="https://hashfinancialgroup.com/our-team/">Our Team</a>
                                    </li>
                                </ul>
                            </div>
                            <div className={cx("form-inline my-2 my-lg-0", Styles.navButtons)}>
                                <a className={cx('nav-link', Styles.navLink, Styles.lnkInvest, Styles.lnkOutline)} href="https://hashfinancialgroup.com/invest">Invest</a>
                                <a className={cx('nav-link', Styles.navLink, Styles.hfgPrimary)} href="https://hashfinancialgroup.com/borrow">Borrow</a>
                            </div>
                        </nav>
                    </div>
                </header>
            </div>
        )
    }
};

const RenderLoanLink = (props) => {
    let skipKeys = [15, 30, 18, 21, 26];
    console.log(props.linKey + " - " + skipKeys.includes(props.linKey));

    if (!skipKeys.includes(props.linKey)) {
        return <a className={cx('dropdown-item', Styles.dropdownItem)} href={"/loan-enquiry?t=" + props.slug}>{props.linkText}</a>
    } else {
        return false;
    }
};

export default Header;