//import React, { Component } from "react";
import React, { Component, Fragment } from 'react';
import Layout from "../components/layout";
import CustomHelmet from '../components/customHelmet';
import Breadcrumbs from "../components/breadcrumbs";
import PageHeading from "../components/pageHeading";
import Cookies from 'universal-cookie';
import HowItWorksSteps from '../components/howItWorksSteps';
// import FooterBottomContact from "../components/footerBottomContact";
import { graphql } from 'gatsby';
import BrokerFinderSearch from "../components/brokers/brokerFinderSearch";
import BrokerListing from "../components/brokers/brokerListing";
import BrokrFinderOtherinfo from "../components/brokers/brokrFinderOtherinfo";
import BrokerFinderTopRated from "../components/brokers/brokerFinderTopRated";
import BrokerSearchPopup from "../components/brokers/brokerSearchPopup";
import allKenticoCloudItemPageDefaultbrokerfinder from "../../data/kentico/allKenticoCloudItemPageDefaultbrokerfinder";
import allKenticoCloudItemPageFindabroker from "../../data/kentico/allKenticoCloudItemPageFindabroker";
import siteName from "../helpers/commons";

const AddClassInBody = () => {
  if (typeof document != 'undefined') {
    if (document.getElementsByTagName('body') 
      && typeof document.getElementsByTagName('body') == 'object') {
      let body = document.getElementsByTagName('body')[0];
      body.className='';
      body.classList.add('brokerFinderPage')
    }
  }
 }

class MortgageBrokerFinder extends Component {
    componentDidMount() {
            //window.location.replace("/loan-enquiry");
    }
    constructor(props) {
      super(props);
      this.state = {openCloseSearchBoxVal:false}
      //this.defaultDrokerFinderContent = props.data.allKenticoCloudItemPageDefaultbrokerfinder;
      this.defaultDrokerFinderContent = allKenticoCloudItemPageDefaultbrokerfinder;
      const howitworklabel = this.defaultDrokerFinderContent.edges[0].node.elements.page_howitworks.elements;
      const defaultPageTitle = this.defaultDrokerFinderContent.edges[0].node.elements.page_titleanddescription.elements.page_title;
      const deaultPageDescription = this.defaultDrokerFinderContent.edges[0].node.elements.page_titleanddescription.elements.page_description;
    
      this.state = {
        breadcrumbs:
          [
            {
              name: 'Mortgage Broker Finder',
              link: 'mortgage-broker-finder'
            }
          ],
          steps_heading:
        {
          label:howitworklabel.label_heading.value
        },
        page_headings:
        {
          title: defaultPageTitle.value,
          description: deaultPageDescription.value
        },
        list:
        [        
          {
            className:'active',
            labelText:howitworklabel.label_step1.value
          },
          {
            className:'',
            labelText:howitworklabel.label_step2.value
          },
          {
            className:'',
            labelText:howitworklabel.label_step3.value
          },
          {
            className:'',
            labelText:howitworklabel.label_step4.value
          },
          {
            className:'',
            labelText:howitworklabel.label_step5.value
          }
        ]  
      };
  }

 openCloseSearchBox(value) { 
     this.setState({
      openCloseSearchBoxVal: value
    })

  }
  closeSearchBrokerBox(value)
  {
     this.setState({
      openCloseSearchBoxVal: value
    })
  }
  render()
  {
   
    let data = this.props.data;
    data.allKenticoCloudItemPageFindabroker = allKenticoCloudItemPageFindabroker;
    data.allKenticoCloudItemPageDefaultbrokerfinder = this.allKenticoCloudItemPageDefaultbrokerfinder;

    const node = allKenticoCloudItemPageFindabroker.edges[0],
        seo_title = node.node.elements.section___seo_content_508c18c__seo_title.value,
        seo_description = node.node.elements.section___seo_content_508c18c__seo_description.value,
        seo_keywords = node.node.elements.section___seo_content_508c18c__seo_keywords.value,
        seo_canonicalurl = node.node.elements.section___seo_content_508c18c__seo_canonicalurl.value;
       
   let breadcrumbs = [{
                    name: 'Mortgage Broker Finder',
                    link: '/mortgage-broker-finder'
                  }];
   let listBrokersPage = false,postcode='',params = {};
    const cookies = new Cookies();
    postcode=cookies.get('postcode');

    if(postcode){
      listBrokersPage = true;
      params.postcode=postcode;
      params.suburb=cookies.get('locality');
      params.state=cookies.get('statecode');
      breadcrumbs = [{
                    name:'Mortgage Broker Finder',
                    link: '/mortgage-broker-finder'
                  },
                  {
                    name:params.state,
                    link: '/mortgage-broker-finder'
                  },
                  {
                    name:params.suburb,
                    link: '/mortgage-broker-finder'
                  }];
    }
    
    return (

        <Layout >
       <CustomHelmet>
          <title>{seo_title}</title>
          <meta name="description" content={seo_description}/>
          <meta name="keywords" content={seo_keywords}/>          
          <meta property="og:description" content={seo_description}/>
          <meta property="og:title" content={seo_title} />
          <meta property="og:url" content={seo_canonicalurl} />          
          <meta name="twitter:description" content={seo_description} />
          <meta name="twitter:title" content={seo_title} />
         <link rel="canonical" href={seo_canonicalurl} />
         
     </CustomHelmet>     
        
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        { !listBrokersPage ? <PageHeading headings={this.state.page_headings} /> : '' }
        {/* <HowItWorksSteps steps={this.state.list} heading={this.state.steps_heading}/> */}
         <div>
            {
              listBrokersPage ? 
              <BrokerListing postcode={postcode} suburb={params.suburb} state={params.state} BrokerId={this.state.brokerID}/>
               : 
               <BrokerFinderSearch searchKenticoLabel={allKenticoCloudItemPageDefaultbrokerfinder} />
            }
          </div>
          <div>
          {
            listBrokersPage ? '' : <div><BrokerFinderTopRated data={data} openCloseSearchBox={this.openCloseSearchBox.bind(this)}/><BrokerSearchPopup openCloseSearchBoxVal={this.state.openCloseSearchBoxVal} closeSearchBrokerBox={this.closeSearchBrokerBox.bind(this)}> <BrokerFinderSearch searchKenticoLabel={allKenticoCloudItemPageDefaultbrokerfinder} searchPopup={true}/></BrokerSearchPopup></div>
          }
          </div>

      
          {/* <div className={'container'}>
              <div className={'row'}>
                <BrokrFinderOtherinfo contentHeading={allKenticoCloudItemPageFindabroker.edges[0].node.elements.bottom_text1heading.value} contentDescription={allKenticoCloudItemPageFindabroker.edges[0].node.elements.bottom_text1description.value} />
               </div>
           </div> */}
        
         {/* <FooterBottomContact content={'asd'} layout={'middle'} inPage={'broker-finder'}></FooterBottomContact>    */}
         {/* {AddClassInBody()} */}
        </Layout>
    );
  }

};

export default MortgageBrokerFinder;

// export const pageQuery = graphql `
//   query {   
//     allHashchingBroker(
//     filter: { show_in_directory:{eq:1} },
//     sort:{
//   fields: [sort_premium,tier_2,monthly_leads,review_count,broker_rating,deals_count],
//   order:[DESC,DESC,ASC,DESC,DESC,DESC]
//   },limit: 4) {
//       edges {
//         node {
//           broker_id
//           user_id
//           firstName
//           lastName
//           fullName
//           profile_pic
//           review_count
//           broker_rating
//           languages
//           premium
//           sort_premium
//           profile_type
//           broker_postcode
//           postcode
//           location
//           specifications
//           profile_view
//           broker_details {
//             business_abn
//             acl_crn
//             avgResponse
//             latest_review            
//             suburb
//             state
//           }
//         }
//       }
//     }    
//    allKenticoCloudItemPageFindabroker {
//     edges {
//       node {
//         elements {
//           paget_titleanddescription {
//             elements {
//               page_title {
//                 value
//               }
//               page_description {
//                 value
//               }
//             }
//           }
//           page_howitworks {
//             elements {
//               label_heading {
//                 value
//               }
//               label_step1 {
//                 value
//               }
//               label_step2 {
//                 value
//               }
//               label_step3 {
//                 value
//               }
//               label_step4 {
//                 value
//               }
//               label_step5 {
//                 value
//               }
//               label_step6 {
//                 value
//               }
//             }
//           }
         
//           bottom_text1heading{
//             value
//           }
//           bottom_text1description{
//             value
//           }
//           section___seo_content_508c18c__seo_title{
//             value
//           }
//           section___seo_content_508c18c__seo_keywords{
//             value
//           }
//           section___seo_content_508c18c__seo_description{
//             value
//           }
//           section___seo_content_508c18c__seo_canonicalurl{
//             value
//           }
//         }
//       }
//     }
//   }
//     allKenticoCloudItemPageDefaultbrokerfinder {
//     edges {
//       node {
//         elements {
//           page_titleanddescription {
//             elements {
//               page_title {
//                 value
//               }
//               page_description {
//                 value
//               }
//             }
//           }
//           page_howitworks {
//             elements {
//               label_heading {
//                 value
//               }
//               label_step1 {
//                 value
//               }
//               label_step2 {
//                 value
//               }
//               label_step3 {
//                 value
//               }
//               label_step4 {
//                 value
//               }
//               label_step5 {
//                 value
//               }
//               label_step6 {
//                 value
//               }
//             }
//           }
//           search_textheading{
//             value
//           }
//           search_placeholder{
//             value
//           }
//           search_buttontext{
//             value
//           }
//           label_topbrokers{
//             value
//           }
//           bottom_text1heading{
//             value
//           }
//           bottom_text1description{
//             value
//           }
//           section___seo_content_508c18c__seo_title{
//             value
//           }
//           section___seo_content_508c18c__seo_keywords{
//             value
//           }
//           section___seo_content_508c18c__seo_description{
//             value
//           }
//           section___seo_content_508c18c__seo_canonicalurl{
//             value
//           }
//         }
//       }
//     }
//   }
//   }
// `;

export const pageQuery = graphql `
  query {   
    allHashchingBroker(
      filter: { show_in_directory: { eq: 1 } },
      sort: {
        fields: [sort_premium, tier_2, monthly_leads, review_count, broker_rating, deals_count],
        order: [DESC, DESC, ASC, DESC, DESC, DESC]
      },
      limit: 4
    ) {
      edges {
        node {
          broker_id
          user_id
          firstName
          lastName
          fullName
          profile_pic
          review_count
          broker_rating
          languages
          premium
          sort_premium
          profile_type
          broker_postcode
          postcode
          location
          specifications
          profile_view
          broker_details {
            business_abn
            acl_crn
            avgResponse
            latest_review            
            suburb
            state
          }
        }
      }
    }
  }
`;
