import React from 'react'
import cx from 'classnames';
import PageTitle from '../components/pageTitle'
import PageSummry from '../components/pageSummry'
import summaryStyles from '../assets/styles/pageSummry.module.css';
import titleStyles from '../assets/styles/pageTitle.module.css';

const PageHeading = props => {
  const headings = props.headings; 
  if(!headings) {
    return null;
  }
 
  return (
     <section className={'pagetitle-section'}>
          <div className={'container'}>
            <div className={'row'}>
              <div className={cx('col-md-12 pagetitle-inner text-center')}>                
              {/* <h1 className={titleStyles.pageTitle}>
                Recommended brokers <br />in your area
              </h1>
              <p className={summaryStyles.pageSummry}>
                <span>HFG Marketplace's broker finder helps you find top-rated brokers in your area that meet your loan needs. Access 100% obligation-free pre-negotiated loan deals</span>
              </p> */}
               <PageTitle title={headings.title} />
               <PageSummry summary1={headings.description}  />
              </div>
            </div>
          </div>
        </section>)
};

export default PageHeading;
